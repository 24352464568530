




import { Component, Vue } from "vue-property-decorator";

import EntityCrud from "./entity/EntityCrud.vue";
import assessorModel from "@/api/assessor.model";

@Component({
	components: { EntityCrud },
})
export default class Assessors extends Vue {
	model: any = assessorModel;

	title = "Assessores";
	tableColumns = ["user.name", "user.email", { name: "Promotor associado", key: "prosecutor.user.name" }];

	formColumns = ["user.email", "user.name", "user.password", "prosecutor"];
}
