import cityModel from "./city.model";
import { CrudModel } from "./_crud";

export class ProsecutorModel extends CrudModel {
	constructor() {
		super("admin/prosecutor", [], [{ fieldKey: "judicialDistrict", model: cityModel }]);
	}

	labelTransformer(entity: any) {
		return `${entity["user.name"]} | ${entity.cpf}`;
	}
}
export default new ProsecutorModel();
